<template>
  <moe-page title="查看助力拉新">
    <moe-form :showBack="false">
      <div class="fwb mb-20 font-20">基本信息</div>

      <moe-describe-list title="" :col="1">
        <moe-describe-item label="活动名称">{{ assistData.name }}</moe-describe-item>
        <moe-describe-item label="排序">{{ assistData.sort }}</moe-describe-item>
        <moe-describe-item label="活动时间">{{ assistData.startTime }} 至 {{ assistData.endTime }}</moe-describe-item>
        <moe-describe-item label="助力时间">{{ `${assistData.deadline}小时` }}</moe-describe-item>
      </moe-describe-list>
    </moe-form>

    <div class="mb-30 margin-0-auto" style="width: 70%;">
      <moe-table
        :stripe="true"
        :numberShow="false"
        :data="assistData.couponId"
        :mode="false"
        :showPage="false">
        <el-table-column label="活动信息" min-width="150">
          <template slot-scope="{ row }">
            <div>{{ row.name }}</div>
            <div>券编号:{{ row.id }}</div>
          </template>
        </el-table-column>

        <el-table-column label="优惠方式" min-width="150">
          <template slot-scope="{ row }">
            {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
          </template>
        </el-table-column>

        <el-table-column label="使用时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <template v-if="row.timeType === 'TIME_RANGE'">
              <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
              <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
            </template>
            <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
          </div>
        </el-table-column>
      </moe-table>
    </div>

    <moe-form :showBack="false" :showClose="true">
      <div class="fwb mb-20 font-20">规则设置</div>

      <moe-describe-list title="" :col="1">
        <moe-describe-item label="助力人数">{{ assistData.num }}</moe-describe-item>
        <moe-describe-item label="助力对象">{{ $moe_format.getAssistTarget(assistData.target) }}</moe-describe-item>
        <moe-describe-item label="奖励限制">{{ `${assistData.maximum ? assistData.maximum + '次' : '' || '不限次数'}` }}</moe-describe-item>
      </moe-describe-list>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageAssistDetail',
  data() {
    return {
      assistData: {},
    }
  },
  methods: {
    /** 获取助力拉新活动详情 */
    req_getAssistDetail() {
      if (this.$route.query.id) {
        this.$moe_api.ASSIST_API.getAssistDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code == 200) {
            this.assistData = {
              ...data.result,
              couponId: [],
            }

            if (data.result.couponId) {
              this.req_getCouponDetail(data.result.couponId);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 获取优惠券详情 */
    req_getCouponDetail(id) {
      this.$moe_api.COUPON_API.getCouponDetail({ id }).then((data) => {
        if (data.code === 200) {
          this.assistData.couponId = [data.result];
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.req_getAssistDetail();
  }
}
</script>

<style lang="scss"></style>